var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isNewChatConfigVm)?_c('div',[(_vm.$store.getters.isChatSet)?[(_vm.currentView === 0)?[_c('page-title',{attrs:{"custom-help-message":_vm.GreetingPageHelpMessage},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}],null,false,4046080315)}),_c('a-card',{attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('field_remove_system_message_alert')}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'hello_remove',
              'hasAnchor': true,
            },
          }}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'leave_remove',
              'hasAnchor': true,
            }
          }}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'psd',
              'options': _vm.getPiramisSpamDetectionModes,
              'clearable': false,
              'hasAnchor': true,
            }
          }}}),(_vm.protectionTypeIsNotInviteValidation)?_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'allow_invite',
              'hasAnchor': true,
            }
          }}}):_vm._e()],1)]:_vm._e(),_c('modules-screens-wrapper',{attrs:{"module-types-list":_vm.greetingModules},on:{"current-view-update":function($event){_vm.currentView = $event}}}),(_vm.currentView === 0)?_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_chat_buttons_block_title')}},[_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('field_chat_buttons_block_description'))},slot:"message"})]),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'chat_buttons_message',
            'placeholders': _vm.CHAT_BUTTONS_MESSAGE_PLACEHOLDERS,
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'options': {
              remove_after: _vm.checkLicense(),
              send_after: _vm.checkLicense()
            },
            'customHelpMessage': _vm.GreetingChatButtonsMessageHelpView,
            'hasAnchor': true,
          },
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'chat_buttons_one_time',
            'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
            'hasAnchor': true,
          }
        }}})],1):_vm._e()]:_c('a-spin',{staticClass:"w-full text-center"})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }